<template>
  <div class="neutral" style="width: 100%; height: 100%; text-align: center">
    <v-progress-circular
      indeterminate
      size="45"
      color="primary"
      class="mt-8"
      v-if="loading"
    ></v-progress-circular>
    <div v-show="showError" class="ui negative message">
      <div class="header">Error ao carregar laudo</div>
    </div>
    <iframe v-show="!showError" ref="viewer" type="application/pdf"></iframe>
  </div>
</template>
<script>
import Api from '@/resources/NajaSocialApi'
export default {
  data() {
    return {
      loading: false,
      showError: false,
    }
  },

  created() {
    this.fetchData()
  },

  methods: {
    fetchData() {
      this.loading = true
      this.showError = false

      Api.medicalReportCombinedPDF(this.$route.query.ids)
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          this.$refs.viewer.setAttribute(
            'src',
            window.URL.createObjectURL(blob)
          )
        })
        .catch(e => {
          this.showError = true
          throw e
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
<style lang="css" scoped>
iframe {
  overflow: auto;
  width: 100%;
  height: 100%;
  border: none;
}

.ui.negative.message {
  max-width: 300px;
  margin: 0 auto;
  margin-top: 100px;
  text-align: center;
}
</style>
